import React,{useEffect,useState} from "react";
import {Helmet} from "react-helmet"
import {Link} from "gatsby"

import style from "../style/peng.module.sass"

export default (props) => {

  const [reactStyle,setReactStyle]=useState({})

  useEffect(() => {

    function updateMargin(){
      const {innerWidth:width,innerHeight:height} = window
      let [p,pp]={}
      if(width>1320){
        const m2=width/2
        p={minHeight:height+'px',boxShadow:'inset '+m2+'px 0 #fafafa'}
        pp={display:'block',marginLeft:width/2-540}
      }else{
        p={background:'#f1f1f1',height:height+"px"}
        pp={display:'block'}
      }
      setReactStyle({
        page:p,
        post:pp,
      })
    }

    updateMargin();
    window.addEventListener("resize",updateMargin);

    return () => {
      window.removeEventListener("resize",updateMargin);
    }
  },[]);

  return(
    <div style={reactStyle.page}>
      <Helmet>
        <title>{props.title} - OK!Melbourne</title>
        <body className={style.postPage}/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-160697028-1"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-160697028-1');
          `}
        </script>
      </Helmet>
      <div className={style.singlePost} style={reactStyle.post}>
        <div className={"sticky-top "+style.backToHome}>
          <Link to="/">
            <ul>Back to Home</ul>
          </Link>
        </div>
        <h1>There's a path for everyone.</h1>
        <h3>Dolores Abernathy</h3>
        {props.children}
      </div>
    </div>
  )
}
