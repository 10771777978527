import React,{useEffect} from "react";
import Pixel from "../card/404"
export default () => {

  useEffect(()=>{
    // if (typeof window !== 'undefined') {
    //   window.location = '/'
    // }
  })

  return(
    <Pixel
      title="Page Not Found">
    </Pixel>
  )
}
